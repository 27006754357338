import {login} from '../services/auth'

const ResetPage = () => {
  localStorage.clear()
  sessionStorage.clear()
  login()
  return null
}

export default ResetPage
